<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-form class="my-2">
            <v-row>
              <v-col md="2">
                <!-- <input
                  type="date"
                  v-model="filter.tanggal_mulai"
                  style="border: 1px solid grey"
                  @keydown.enter="optionChange()"
                /> -->
                <v-text-field
                  label="Tanggal Awal *"
                  type="date"
                  v-model="filter.tanggal_mulai"
                  outlined
                  hide-details
                  @keydown.enter="optionChange()"
                />
              </v-col>
              <v-col md="1"> Sampai </v-col>
              <v-col md="2">
                <!-- <input
                  type="date"
                  v-model="filter.tanggal_akhir"
                  style="border: 1px solid grey"
                  @keydown.enter="optionChange()"
                /> -->
                <v-text-field
                  label="Tanggal Akhir *"
                  type="date"
                  v-model="filter.tanggal_akhir"
                  outlined
                  hide-details
                  @keydown.enter="optionChange()"
                />
              </v-col>
              <v-col md="2">
                <v-autocomplete
                  label="Akun"
                  v-model="filter.akun"
                  hide-details
                  :items="akuns"
                  dense
                  item-text="text"
                  item-value="value"
                  outlined
                  @change="optionChange()"
                ></v-autocomplete>
              </v-col>

              <v-col md="3">
                <v-btn color="primary" small @click="optionChange()"> Lihat </v-btn>
                <v-btn color="primary" class="ml-2" small @click="cetak"> Cetak </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <hr />

          <div id="printMe">
            <table cellpadding="0" cellspacing="0" class="header">
              <tr>
                <td style="width: 20%">
                  <img style="width: 150px; height: auto" :src="require('@/assets/images/logos/logo.png')" />
                </td>
                <td style="width: 80%">
                  <center>
                    <span v-if="kop.kop_1 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_1 }} <br
                    /></span>
                    <span v-if="kop.kop_2 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_2 }} <br
                    /></span>
                    <span v-if="kop.kop_3 != ''" style="font-size: 10pt">{{ kop.kop_3 }} ><br /></span>
                    <span v-if="kop.kop_4 != ''" style="font-size: 10pt">{{ kop.kop_4 }} ><br /></span>
                  </center>
                </td>
                <td style="width: 20%">&nbsp;</td>
              </tr>
            </table>
            <h3 style="text-align: center">
              Laporan Buku Besar Periode
              {{ formatDate(filter.tanggal_mulai) + ' s/d ' + formatDate(filter.tanggal_akhir) }}
            </h3>

            <table cellpadding="0" cellspacing="0" class="body" v-for="(itemH, indexH) in desserts" :key="indexH">
              <!-- <v-simple-table class="mb-8" dense v-for="(itemH, indexH) in desserts" :key="indexH">
              <template v-slot:default> -->
              <thead>
                <tr>
                  <td colspan="5" style="font-weight: bold">{{ itemH.kategori }} - {{ itemH.nama }}</td>
                  <td style="font-weight: bold; text-align: right">Nomor: {{ itemH.nomor }}</td>
                </tr>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>No Transaksi</th>
                  <th>Keterangan</th>
                  <th>Debit</th>
                  <th>Kredit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in itemH.transaksi" :key="index">
                  <td style="text-align: center">{{ index + 1 }}</td>
                  <td>
                    <span v-if="index === 0">
                      {{ formatDate(item.tanggal) }}
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          desserts[indexH].transaksi[index].tanggal === desserts[indexH].transaksi[index - 1].tanggal
                        "
                      ></span>
                      <span v-else>{{ formatDate(item.tanggal) }}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="index === 0">
                      {{ item.id_header }}
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          desserts[indexH].transaksi[index].id_header ===
                          desserts[indexH].transaksi[index - 1].id_header
                        "
                      ></span>
                      <span v-else>{{ item.id_header }}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="index === 0">
                      {{ item.keterangan }}
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          desserts[indexH].transaksi[index].id_header ===
                            desserts[indexH].transaksi[index - 1].id_header &&
                          desserts[indexH].transaksi[index].keterangan ===
                            desserts[indexH].transaksi[index - 1].keterangan
                        "
                      ></span>
                      <span v-else>{{ item.keterangan }}</span>
                    </span>
                  </td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.debit) }}</td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.kredit) }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td style="text-align: right">
                    Saldo : {{ new Intl.NumberFormat(['id']).format(footer[indexH].saldo) }}
                  </td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer[indexH].debit) }}</td>
                  <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer[indexH].kredit) }}</td>
                </tr>
              </tbody>
              <!-- </template>
            </v-simple-table> -->
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import '@/styles/print.css'
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  data: () => ({
    desserts: [],
    loading: true,
    filter: {
      akun: -1,
      // tanggal_mulai: new Date().toJSON().slice(0, 8).replace(/-/g, '-') + '01',
      tanggal_mulai: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      tanggal_akhir: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    },
    footer: [],
    kop: {},
    akuns: [],
  }),

  computed: {
    token() {
      return this.$store.getters.token
    },
  },

  watch: {},

  created() {
    this.getDataFromApi()
    this.getParameterKop()
    this.getAkunsFromApi()
  },

  methods: {
    async cetak() {
      // Pass the element id here
      await this.$htmlToPaper('printMe', {})
    },
    getAkunsFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Laporan/getAkunFilter`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // console.log(response.data.data)
            response.data.data.forEach(element => {
              this.akuns.push({
                text: element.nomor + ' ' + element.nama,
                value: element.id,
              })
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getParameterKop() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }

      this.$store.dispatch('loading', true)
      axios
        .get(`${apiRoot}/api/Dashboard/getParameterKop`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.kop = response.data.data.parameter
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('akun', this.filter.akun)
      form.append('tanggal_mulai', this.filter.tanggal_mulai)
      form.append('tanggal_akhir', this.filter.tanggal_akhir)
      axios
        .post(`${apiRoot}/api/Laporan/buku_besar`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.footer = []
            response.data.data.forEach((element, index) => {
              this.footer[index] = {
                debit: 0,
                kredit: 0,
                saldo: 0,
              }
            })
            response.data.data.forEach((element, index) => {
              element.transaksi.forEach(elementD => {
                // console.log(elementD)
                this.footer[index].debit += parseFloat(elementD.debit)
                this.footer[index].kredit += parseFloat(elementD.kredit)
                this.footer[index].saldo += parseFloat(elementD.debit) - parseFloat(elementD.kredit)
              })
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    optionChange() {
      // console.log(this.filter.tanggal_mulai)
      // console.log(this.filter.tanggal_akhir)
      if (this.filter.tanggal_mulai != '' && this.filter.tanggal_akhir != '') {
        this.getDataFromApi()
      }
    },
  },
}
</script>
